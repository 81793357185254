<template>
  <div>
    <!-- Filter button -->
    <b-dropdown variant="info" size="sm">
      <template #button-content>
        <b-icon-filter />
        Filter
        <b-badge pill variant="light" v-if="totalSelected">{{ totalSelected }}</b-badge>
      </template>
      <b-dropdown-item @click="openLocationFilter">
        Locatie
        <b-badge pill variant="info" v-if="selectedLocations.length">{{ selectedLocations.length }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-item @click="openStatusFilter">
        Status
        <b-badge pill variant="info" v-if="selectedStatuses.length">{{ selectedStatuses.length }}</b-badge>
      </b-dropdown-item>
      <b-dropdown-divider v-if="totalSelected" />
      <b-dropdown-item variant="danger" @click="clearFilter" v-if="totalSelected">Herstel filter</b-dropdown-item>
    </b-dropdown>

    <!-- Location filter -->
    <b-modal title="Locatie Filter" id="locationfilter" hide-footer>
      <div v-for="location in locations" :key="location.id">
        <b-checkbox v-model="selectedLocations" :value="location.name">
          {{ location.name }}
        </b-checkbox>
      </div>
      <div class="mt-2">
        <b-button variant="danger" block @click="selectedLocations = []">Deselecteer alles</b-button>
        <b-button variant="info" block @click="applyLocationFilter">Opslaan</b-button>
      </div>
    </b-modal>

    <!-- Status Filter -->
    <b-modal title="Status Filter" id="statusfilter" hide-footer>
      <div v-for="status in statuses" :key="status.id">
        <b-checkbox v-model="selectedStatuses" :value="status.name">
          {{ status.name }}
        </b-checkbox>
      </div>
      <div class="mt-2">
        <b-button variant="danger" block @click="selectedStatuses = []">Deselecteer alles</b-button>
        <b-button variant="info" block @click="applyStatusFilter">Opslaan</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {mapActions} from "vuex"
export default {
  props: {
    input: Array
  },
  watch: {
    input() {
      this.doFilter()
    }
  },
  data() {
    return {
      selectedLocations: [],
      locations: [],
      selectedStatuses: [],
      statuses: []
    }
  },
  computed: {
    totalSelected() {
      return this.selectedLocations.length + this.selectedStatuses.length
    }
  },
  methods: {
    ...mapActions(['apiGet']),
    applyLocationFilter() {
      localStorage.setItem('filter_locations', JSON.stringify(this.selectedLocations))
      this.doFilter()
      this.$bvModal.hide('locationfilter')
    },
    applyStatusFilter() {
      localStorage.setItem('filter_status', JSON.stringify(this.selectedStatuses))
      this.doFilter()
      this.$bvModal.hide('statusfilter')
    },
    clearFilter() {
      this.selectedStatuses = []
      this.selectedLocations = []

      localStorage.setItem('filter_locations', JSON.stringify(this.selectedLocations))
      localStorage.setItem('filter_status', JSON.stringify(this.selectedStatuses))
      this.doFilter()
    },
    doFilter() {
      let rtn = [];
      this.input.forEach((item) => {
        let loc = false;
        let stat = false

        if(this.selectedLocations != null && this.selectedLocations.length > 0) {
          if(item.locations.length > 0 && this.selectedLocations.includes(item.locations[0].name)) {
            loc = true
          }
        } else {
          loc = true
        }

        if(this.selectedStatuses != null && this.selectedStatuses.length > 0) {
          if(this.selectedStatuses.includes(item.status.name)) {
            stat = true
          }
        } else {
          stat = true
        }

        if(loc && stat) {
          rtn.push(item)
        }
      })

      this.$emit('change', rtn)
      return rtn
    },
    openLocationFilter() {
      this.apiGet("locations").then((result) => {
        this.locations = result.data.data
        this.$bvModal.show('locationfilter')
      })
    },
    openStatusFilter() {
      this.apiGet("statuses").then((result) => {
        this.statuses = result.data.data
        this.$bvModal.show('statusfilter')
      })
    }
  },
  mounted() {
    if(localStorage.getItem('filter_locations') != null) {
      this.selectedLocations = JSON.parse(localStorage.getItem('filter_locations'))
    }
    if(localStorage.getItem('filter_status') != null) {
      this.selectedStatuses = JSON.parse(localStorage.getItem('filter_status'))
    }

    this.doFilter()
  }
}
</script>