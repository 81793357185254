import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Dashboard from "@/views/Dashboard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Dashboard
  },

  /**
   * Evenementen
   */
  {
    path: '/events/:date',
    name: 'Events',
    component: () => import('../views/events/Events')
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: () => import('../views/events/Event')
  },
  {
    path: '/searchevents',
    name: 'Zoek Events',
    component: () => import('../views/events/SearchEvents')
  },
  {
    path: '/group/:id',
    name: 'Eventgroep',
    component: () => import('../views/events/Group')
  },

  /**
   * Rooster
   */
  {
    path: '/roster/login',
    name: 'Rooster Login',
    component: () => import('../views/roster/RosterLogin')
  },
  {
    path: '/roster/:date',
    name: 'Rooster',
    component: () => import('../views/roster/Roster')
  },
  {
    path: '/publicroster/:date',
    name: 'Publiek Rooster',
    component: () => import('../views/roster/PublicRoster')
  },
  {
    path: '/hours',
    name: 'Uren',
    component: () => import('../views/roster/CheckHours')
  },

  /**
   * Authenticatie
   */
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/auth/Login'),
    meta: {
      guestAccess: true
    }
  },
  {
    path: '/logout',
    name: 'Log uit',
    component: () => import('../views/auth/Logout')
  },

  /**
   * Public Dashboard
   */
  {
    path: '/publicdashboard/:filter',
    name: 'Publiek Dashboard',
    component: () => import('../views/PublicDashboard')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  // Query login
  if(to.query.apikey != null && to.query.subdomain != null) {
    const form = {
      subdomain: to.query.subdomain,
      apikey: to.query.apikey,
      domain: to.query.domain,
      once: true
    }
    store.dispatch('login', form).then(() => {
      next(to.path)
    }).catch(() => {
      next('/login')
    })
    return
  }

  // Else
  if(!to.matched.some(record => record.meta.guestAccess)) {
    if(store.getters.isLoggedIn) {
      next()
      return
    }
    store.dispatch("relogin").then(() => {
      next()
    }).catch(() => {
      next('/login')
    })
  } else {
    next()
  }
})

export default router
