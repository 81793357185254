var datehelper = (date) => {
    if(date == null)
        date = new Date().toISOString()

    let dt = new Date(date)

    /**
     * Add a amount of days to the current date
     * @param days
     * @return {*}
     */
    var addDays = (days) => {
        dt.setDate(dt.getDate() + days)
        return datehelper(dt)
    }

    /**
     * Subtract a amount of days to the current date
     * @param days
     * @return {*}
     */
    var subtractDays = (days) => {
        dt.setDate(dt.getDate() - days)
        return datehelper(dt)
    }

    /**
     * Get a formatted date (DD-MM-YYYY)
     * @returns {string}
     */
    var formatDate = () => {
        return dt.getDate() +"-"+ (dt.getMonth() + 1) +"-"+ dt.getFullYear()
    }

    /**
     * Get a reversed formatted date (YYYY-MM-DD)
     * @returns {string}
     */
    var formatRevDate = () => {
        return dt.getFullYear() +"-"+ (dt.getMonth() + 1) +"-"+ dt.getDate()
    }

    /**
     * Get a formatted time (HH:MM)
     * @returns {string}
     */
    var formatTime = () => {
        const h = (dt.getHours() < 10) ? "0"+ dt.getHours() : dt.getHours()
        const m = (dt.getMinutes() < 10) ? "0"+ dt.getMinutes() : dt.getMinutes()
        return h +":"+ m
    }

    /**
     * Return a weekday
     * @return {string}
     */
    var getWeekday = () => {
        const days = ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"]
        return days[dt.getDay()]
    }


    return {
        addDays,
        subtractDays,

        formatDate,
        formatRevDate,
        formatTime,

        getWeekday
    }
}
export {
    datehelper
}