import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loggedIn: false,
        subdomain: '',
        domain: 'nl',
        apiKey: '',
        apiCalls: 0,
        error: '',
        rosterId: null,
        hideMenu: false
    },
    mutations: {
        incrementApiCalls(state) {
            state.apiCalls++
        },

        setDomain(state, domain) {
            state.domain = domain

            // Set Axios headers
            axios.defaults.headers.common['domain'] = domain
        },

        setCredentials(state, data) {
            state.subdomain = data.subdomain
            state.apiKey = data.apiKey
            state.loggedIn = true

            // Set Axios headers
            axios.defaults.headers.common['api_key'] = data.apiKey
            axios.defaults.headers.common['subdomain'] = data.subdomain
        },

        clearCredentials(state) {
            state.domain = 'nl'
            state.subdomain = ''
            state.apiKey = ''
            state.rosterId = null
            state.loggedIn = false
            axios.defaults.headers.common['api_key'] = null
            axios.defaults.headers.common['subdomain'] = null
            axios.defaults.headers.common['domain'] = null
        },

        setRosterId(state, rosterId) {
            state.rosterId = rosterId
        },

        clearRosterId(state) {
            state.rosterId = null
        },

        error(state, error) {
            state.error = error
        },

        setHideMenu(state, menu) {
            state.hideMenu = menu
        }
    },
    actions: {
        login(context, form) {
            context.commit('error', '')
            return new Promise((resolve, reject) => {
                context.commit('incrementApiCalls')
                let headers = {
                    api_key: form.apikey,
                    subdomain: form.subdomain,
                    domain: form.domain
                }
                const time = new Date().getTime()
                axios.get("https://sayyes-proxy.daworld.workers.dev/api/statuses/?_="+ time, { headers: headers }).then((response) => {
                    if (response.status === 200) {
                        context.commit('setDomain', form.domain)
                        context.commit('setCredentials', {subdomain: form.subdomain, apiKey: form.apikey})
                        if (form.once == null || form.once === false) {
                            localStorage.setItem("subdomain", form.subdomain)
                            localStorage.setItem("domain", form.domain)
                            localStorage.setItem("apikey", form.apikey)
                        }
                        resolve()
                    }
                }).catch(() => {
                    context.commit('error', 'login_failed')
                    reject()
                })
            })
        },

        logout(context) {
            context.commit("clearCredentials")
            localStorage.clear()
        },

        apiGet(context, endpoint) {
            const time = new Date().getTime()
            const url = "https://sayyes-proxy.daworld.workers.dev/api/" + endpoint + "?_="+ time
            return new Promise((resolve, reject) => {
                axios.get(url).then((result) => {
                    resolve(result)
                }).catch((reason) => {
                    reject(reason)
                })
            })
        },

        apiGetPaginate(context, endpoint) {
            const time = new Date().getTime()
            const url = "https://sayyes-proxy.daworld.workers.dev/api/" + endpoint + "?_="+ time

            return new Promise((resolve => {
                let rurl = null;
                let data = []

                function test(page, book) {
                    if (page != null && book != null)
                        rurl = url + "&page=" + page + "&book=" + book
                    else
                        rurl = url

                    axios.get(rurl).then((result) => {
                        data = data.concat(result.data.data)

                        if (result.data.pagination != null && result.data.pagination.next != null) {
                            const regpage = result.data.pagination.next.match(/page=([0-9]+)/)
                            const regbook = result.data.pagination.next.match(/book=([0-9]+)/)

                            test(regpage[1], regbook[1])
                        } else {
                            resolve(data)
                        }
                    }).catch(() => {
                        resolve(data)
                    })
                }

                test(null, null)
            }))
        },

        relogin(context) {
            return new Promise((resolve, reject) => {
                const subdomain = localStorage.getItem("subdomain");
                const domain = localStorage.getItem("domain");
                const apiKey = localStorage.getItem("apikey");
                const rosterId = localStorage.getItem("rosterid");
                if (subdomain != null && apiKey != null) {
                    console.log('Found api credentials for %s.', subdomain)
                    context.commit('incrementApiCalls')
                    context.commit("setCredentials", {subdomain: subdomain, apiKey: apiKey})
                    if (domain != null) {
                        context.commit("setDomain", domain)
                    }
                    if (rosterId != null) {
                        context.commit("setRosterId", rosterId)
                    }
                    resolve()
                    return
                }
                reject()
            })
        },

        hideMenu(context) {
            context.commit('setHideMenu', true)
        },
        showMenu(context) {
            context.commit('setHideMenu', false)
        }
    },
    modules: {},
    getters: {
        subdomain: state => state.subdomain,
        apiKey: state => state.apiKey,
        isLoggedIn: state => state.loggedIn,
        error: state => state.error,
        rosterId: state => state.rosterId,
        menu: state => !state.hideMenu
    }
})
