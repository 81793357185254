<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-icon-newspaper/>
        Nieuws
      </b-card-header>
      <b-list-group flush>
        <b-list-group-item v-for="(item, index) in news" :key="index">
          <div class="small text-muted float-md-right">{{item.date}}</div>
          <div class="font-weight-bold">{{item.title}}</div>
          <div v-html="item.body"></div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      news: [
        {
          title: 'SayYes iOS app',
          date: 'Ma 17 januari 2022',
          body: 'SayYes is nu ook als iOS app beschikbaar in de <a href="https://apps.apple.com/us/app/sayyes/id1603237724" target="_blank">App Store</a>.'
        },
        {
          title: 'Licentiewijziging',
          date: 'Ma 6 september 2021',
          body: 'SayYes is vanaf heden zonder licentie gratis te gebruiken voor alle culturele instellingen.'
        },
        {
          title: 'Officiële website live',
          date: 'Za 19 december 2020',
          body: 'Vandaag is de officiële website van SayYes gelanceerd. Kijk op <a href="https://sayyesapp.nl" target="_blank">sayyesapp.nl</a> voor meer informatie over SayYes.'
        }
      ]
    }
  }
}
</script>