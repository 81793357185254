<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-brand :to="{name: 'Home'}">{{ appName }}</b-navbar-brand>

    <b-navbar-toggle target="navbar-collapse"></b-navbar-toggle>

    <b-collapse id="navbar-collapse" is-nav v-if="menu">
      <b-navbar-nav v-if="isLoggedIn">
        <b-nav-item :to="{name: 'Home'}" :active="$route.name === 'Home'">Home</b-nav-item>
        <b-nav-item :to="{name: 'Events', params: { date: 'today' }}" :active="$route.name === 'Events' || $route.name === 'Event'">Evenementen</b-nav-item>
        <b-nav-item :to="{name: 'Rooster', params: { date: 'today' }}" :active="$route.name === 'Rooster' || $route.name === 'Rooster Login'">Rooster</b-nav-item>
        <b-nav-item :to="{name: 'Publiek Rooster', params: { date: 'today' }}" :active="$route.name === 'Publiek Rooster'">Publiek Rooster</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto" v-if="isLoggedIn">
        <b-nav-item :to="{name: 'Zoek Events'}" :active="$route.name === 'Zoek Events'"><b-icon-search class="d-none d-md-inline" /> <span class="d-md-none">Zoeken</span></b-nav-item>
        <b-nav-item :to="{name: 'Log uit'}" :active="$route.name === 'Log uit'"><b-icon-lock /></b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters(['isLoggedIn', 'menu']),
    appName() {
      return "SayYes"
    }
  },
  methods: {
    ...mapActions(['showMenu'])
  }
}
</script>