<template>
  <div class="row">
    <div class="col-md-8">
      <b-card-group v-if="topEvents.length > 0" class="mb-3" deck>
        <b-card v-for="location in topEvents" :key="location.name" :title="location.name">
          <b-spinner variant="info" v-if="loading"></b-spinner>
          <div v-else>
            <div v-for="event in location.events" :key="location.name+event.id">
              <b-card-text class="small" v-if="event.locations[0].name === location.name">
                <router-link :to="{name: 'Event', params: {id: event.id}}" class="text-dark">{{ event.name }} <b-icon-arrow-up-right/></router-link>
                <div class="text-muted">
                  {{ event.status.name }}
                  &ndash;
                  {{ datehelper(event.starttime).formatTime() }}
                  &ndash;
                  {{ datehelper(event.endtime).formatTime() }}
                </div>
              </b-card-text>
            </div>
          </div>
        </b-card>
      </b-card-group>
      <b-card no-body>
        <b-card-header>
          <div class="d-flex align-items-center">
            <div class="mr-auto">
              Vandaag
            </div>
            <b-button-group>
              <filterbutton filtertype="location" :input="events" @change="applyFilter" />
            </b-button-group>
          </div>
        </b-card-header>
        <div class="row" v-if="!loading">
          <div class="col-lg-6 border-right pr-lg-0">
            <b-list-group flush>
              <b-list-group-item><strong>Evenementen</strong></b-list-group-item>
              <b-list-group-item v-for="event in filterEvents" :key="event.id">
                <span class="float-right small text-muted pt-1">
                  {{ datehelper(event.starttime).formatTime() }}
                  &ndash;
                  {{ datehelper(event.endtime).formatTime() }}
                </span>
                <router-link :to="{name: 'Event', params: { id: event.id }}">{{ event.name }}</router-link>
                <div class="text-muted small">
                  <span>{{ event.status.name }}</span>
                  &ndash;
                  <span v-for="location in event.locations" :key="location.id">{{ location.name }}</span>
                </div>
              </b-list-group-item>
              <b-list-group-item v-if="filterEvents.length == 0" class="text-center font-italic small text-muted">
                Er zijn geen evenementen gevonden.
              </b-list-group-item>
            </b-list-group>
          </div>
          <div class="col-lg-6 pl-lg-0">
            <b-list-group flush>
              <b-list-group-item><strong>Personeel</strong></b-list-group-item>
              <b-list-group-item v-for="shift in personel" :key="shift.id">
                <span class="float-right small text-muted pt-1">
                  {{ datehelper(shift.start).formatTime() }}
                  &ndash;
                  {{ datehelper(shift.end).formatTime() }}
                </span>
                {{ shift.resource.name }}
                <div class="small">
                  <router-link :to="{name:'Event', params:{id:shift.event.id}}" v-if="shift.event.id" class="text-muted">{{ shift.event.name }} <b-icon-arrow-up-right/></router-link>
                  <span class="text-muted" v-else>{{ shift.event.name }}</span>
                </div>
              </b-list-group-item>
              <b-list-group-item v-if="personel.length == 0" class="text-center font-italic small text-muted">
                Er is geen personeel ingepland.
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
        <b-card-body v-if="!loading && !events.length">
          <span class="small text-center text-muted font-italic">Geen evenementen gevonden.</span>
        </b-card-body>
        <b-card-body v-if="loading">
          <b-spinner variant="info"></b-spinner>
        </b-card-body>
      </b-card>
    </div>
    <div class="col-md-4 mt-3 mt-md-0">
      <b-card v-if="favoriteEvents.length > 0" class="mb-3" no-body>
        <b-card-header>
          Favorieten
          <b-badge variant="info" pill>{{ favoriteEvents.length }}</b-badge>
        </b-card-header>
        <b-list-group flush>
          <b-list-group-item v-for="event in favoriteEvents" :key="'f'+ event.id">
            <router-link :to="{name: 'Event', params: {id: event.id}}">{{ event.name }}</router-link>
            <div class="text-muted small">
              {{ datehelper(event.starttime).formatDate() }} {{ datehelper(event.starttime).formatTime() }}
              &ndash;
              {{ event.locations[0].name }}
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <news></news>
      <changelog class="mt-3"></changelog>
      <b-card header="iOS App" class="mt-3">
        <div v-if="qrUrl.length > 0" class="text-center">
          <p>Scan de onderstaande QR code met de SayYes app om snel in te kunnen loggen.</p>
          <img :src="qrUrl" />
        </div>
        <div v-else class="text-center">
          <p>Gebruik je de <a href="https://apps.apple.com/us/app/sayyes/id1603237724" target="_blank">SayYes iOS app</a>? Druk dan op de onderstaande knop om een QR code te genereren waarmee je eenvoudig in de app kunt inloggen.</p>
          <b-button @click="getQRCode" variant="info">QR code genereren</b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex"
import news from "../components/News"
import changelog from "../components/Changelog"
import filterbutton from "../components/Filter"
import {datehelper} from "@/helpers/datehelper";

export default {
  data() {
    return {
      loading: true,
      fields: {},
      events: [],
      personel: [],
      filterEvents: [],
      locationFilter: [],
      topEvents: [],
      favoriteEvents: [],
      qrUrl: ""
    }
  },
  computed: {
    date: () => {
      let dt = new Date()
      return dt.getDate() +"-"+ (dt.getMonth()+1) +"-"+ dt.getFullYear()
    }
  },
  methods: {
    ...mapActions(['apiGet']),
    datehelper,
    updateEvents() {
      this.events = []
      this.loading = true
      this.apiGet("events/date:%23today").then((result) => {
        this.events = result.data.data

        this.apiGet("resourcebookings/date:%23today type:human").then((result) => {
          result.data.data.forEach((item) => {
            if(item.resource.resourcetype === 'human') {
              this.personel.push(item)
            }
          })

          this.getTopEvents()
          this.getFavoriteEvents()
          this.loading = false
        })
      })
    },
    applyFilter(data) {
      this.filterEvents = data

      if(localStorage.getItem('filter_locations') != null) {
        this.locationFilter = JSON.parse(localStorage.getItem('filter_locations'))
      } else {
        this.locationFilter = []
      }
    },
    getTopEvents() {
      let events = []
      this.locationFilter.forEach((location) => {
        let locationevents = []
        this.filterEvents.forEach((event) => {
          if(event.locations[0].name === location) {
            locationevents.push(event)
          }
        });
        if(locationevents.length > 0) {
          events.push({"name": location, "events": locationevents})
        }
      });
      this.topEvents = events;
      return events;
    },
    getFavoriteEvents() {
      this.favoriteEvents = []
      let events = JSON.parse(localStorage.getItem('favorite_events'))
      if(events != null) {
        events.forEach((id) => {
          this.apiGet("event/" + id).then((result) => {
            this.favoriteEvents.push(result.data)
          })
        })
      }
    },
    getQRCode() {
      const subdomain = localStorage.getItem("subdomain");
      const domain = localStorage.getItem("domain");
      const apiKey = localStorage.getItem("apikey");
      const data = btoa(subdomain +".yesplan."+ domain +"-"+ apiKey)
      this.qrUrl = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+ data
    }
  },
  mounted() {
    this.updateEvents()

    if(localStorage.getItem('filter_locations') != null) {
      this.locationFilter = JSON.parse(localStorage.getItem('filter_locations'))
    }
  },
  components: {
    news,
    changelog,
    filterbutton
  }
}
</script>