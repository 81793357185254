<template>
  <div id="app">
    <navbar></navbar>
    <b-container fluid="" class="mt-3 mb-4 mt-lg-4">
      <router-view/>
      <div class="mt-3 text-muted text-center">
        &copy; SayYes {{ year }} - <a href="#" @click.prevent="$bvModal.show('colofon')" class="text-info">versie: <changelog versiononly/></a>
      </div>
    </b-container>

    <b-modal id="colofon" title="Colofon" hide-footer>
      <div class="d-block">
        <p>
          SayYes is ontwikkeld door Edwin de Vries.<br />
          Bij vragen of problemen kan er per e-mail contact worden opgenomen: <a href="mailto:edwin@daworld.nl?subject=SayYes">edwin@daworld.nl</a>.
        </p>
        <p>Er zijn verschillende technieken gebruikt om deze applicatie tot stand te brengen:</p>
        <ul>
          <li @dblclick="hideMenu()">Vue.js</li>
          <li @dblclick="showMenu()">Bootstrap</li>
          <li>Bootstrap-Vue</li>
          <li>Cloudflare Workers</li>
          <li>Surge.sh</li>
        </ul>
      </div>
      <b-button variant="info" class="mt-3" block @click="$bvModal.hide('colofon')">Sluiten</b-button>
    </b-modal>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import {mapGetters, mapActions} from "vuex"
import changelog from "@/components/Changelog"

export default {
  components: {Navbar,changelog},
  computed: {
    ...mapGetters(['isLoggedIn']),
    year: () => {
      return new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions(['hideMenu', 'showMenu'])
  }
}
</script>