<template>
  <div :class="versiononly ? 'd-inline':''">
    <span v-if="versiononly">{{ currentVersion }}</span>
    <b-card v-else no-body>
      <b-card-header>
        Updates
      </b-card-header>
      <b-list-group flush>
        <b-list-group-item v-for="change in limitedChangelog" :key="change.version">
          <div class="small text-muted float-md-right">{{ change.date }}</div>
          <div class="font-weight-bold">{{ displayVersion(change.version) }}</div>
          <div>
            {{ change.description }}
          </div>
          <ul class="mb-n1">
            <li v-for="(item, index) in change.items" :key="'s'+change.version+index">{{ item }}</li>
          </ul>
        </b-list-group-item>
      </b-list-group>
      <b-card-footer>
        <b-button size="sm" variant="info" @click="$bvModal.show('changelogmodal')">Bekijk {{ changelog.length - 2 }}
          meer...
        </b-button>
      </b-card-footer>
    </b-card>
    <b-modal v-if="!versiononly" id="changelogmodal" hide-footer title="Changelog">
      <p>Hieronder staan de laatste wijzigingen aan SayYes.</p>
      <b-list-group>
        <b-list-group-item v-for="change in changelog" :key="'f'+change.version">
          <div class="small text-muted float-md-right">{{ change.date }}</div>
          <div class="font-weight-bold">{{ displayVersion(change.version) }}</div>
          <div>
            {{ change.description }}
          </div>
          <ul class="mb-n1">
            <li v-for="(item, index) in change.items" :key="'f'+change.version+index">{{ item }}</li>
          </ul>
        </b-list-group-item>
      </b-list-group>
      <b-button block class="mt-3" variant="info" @click="$bvModal.hide('changelogmodal')">Sluiten</b-button>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    versiononly: Boolean
  },
  data() {
    return {
      changelog: [
        {
          date: 'Za 19 november 2022',
          version: 1000770,
          items: [
            "Bug opgelost in locatiefilters",
            "Resourcevelden worden nu goed weergegeven"
          ]
        },
        {
          date: 'Di 7 september 2021',
          version: 1000670,
          items: [
              "Vermindering API Calls",
              "Beveiliging verbeterd",
              "Nieuw: Inloggen met yesplan.be-domein"
          ]
        },
        {
          date: 'Wo 1 september 2021',
          version: 1000570,
          items: [
              "Nieuw: Publiek rooster"
          ]
        },
        {
          date: 'Ma 23 augustus 2021',
          version: 1000532,
          items: [
              "Het evenementenoverzicht toont nu de geboekte dagen op basis van het filter in de agenda"
          ]
        },
        {
          date: 'Wo 18 augustus  2021',
          version: 1000531,
          items: [
              "Rooster inlogsuggesties iOS-proof gemaakt",
              "Eventgroepen hebben een eigen pagina"
          ]
        },
        {
          date: 'Ma 16 augustus 2021',
          version: 1000470,
          items: [
              "Rooster is nu eenvoudiger te koppelen door op naam te zoeken"
          ]
        },
        {
          date: 'Di 3 augustus 2021',
          version: 1000371,
          items: [
              "Mogelijkheid toegevoegd om rooster uit te loggen"
          ]
        },
        {
          date: 'Za 19 december 2020',
          version: 1000370,
          //description: 'Kleine aanpassingen',
          items: [
            "Groepsnaam toegevoegd aan infoblok in evenement",
            "Dashboard meld nu wanneer er geen evenementen plaatsvinden of personeel gepland is",
            "Kleine stijlfoutjes opgelost"
          ]
        },
        {
          date: 'Ma 14 december 2020',
          version: 1000270,
          description: 'Lancering SayYes',
          items: [
            "SayYes is gelanceerd voor het grote publiek"
          ]
        },
        {
          date: 'Vr 11 december 2020',
          version: 1000139,
          description: 'Favoriet',
          items: [
            "Het is nu mogelijk om een evenement als favoriet in te stellen. Favorieten worden op het dashboard weergegeven",
            "De SayYes tekst linkt nu naar de homepage",
            "Een aantal fouten opgelost"
          ]
        },
        {
          date: 'Wo 9 december 2020',
          version: 1000138,
          description: 'Flint update',
          items: [
            "Lege gefilterde locaties worden nu verborgen op dashboard",
            "Indien aanwezig worden bijlagen bij evenementen weergegeven"
          ]
        },
        {
          date: 'Wo 4 november 2020',
          version: 1000137,
          description: 'Rooster update',
          items: [
            "In de agenda zijn de werkdagen nu blauw gekleurd voor een snel overzicht"
          ]
        },
        {
          date: 'Di 3 november 2020',
          version: 1000136,
          description: 'Zoeken naar evenementen',
          items: [
            "Zoekfunctie toegevoegd"
          ]
        },
        {
          date: 'Do 22 oktober 2020',
          version: 1000135,
          description: 'Snelheid verbeterd + bugfixes',
          items: [
            "De snelheid van SayYes is aanzienlijk verbeterd door het cachen van gegevens uit Yesplan",
            "Verschillende bugfixes",
            "Onzichtbare links aangeduid met een pijl rechtsomhoog"
          ]
        },
        {
          date: 'Di 20 oktober 2020',
          version: 1000134,
          description: 'Algemene verbeteringen',
          items: [
            "Changelog weergave beperkt tot twee updates",
            "Ongepubliceerde rooster items gemarkeerd",
            "Nieuw statusfilter",
            "Filter knop op evenementenpagina verplaatst"
          ]
        },
        {
          date: 'Vr 16 oktober 2020',
          version: 1000133,
          description: 'Dashboard',
          items: [
            "Wanneer je een locatiefilter instelt zie je deze locaties boven in je dashboard",
            "Dag toegevoegd aan het rooster"
          ]
        },
        {
          date: 'Do 15 oktober 2020',
          version: 1000132,
          description: 'Rooster',
          items: [
            "Rooster module toegevoegd",
            "Filters toegevoegd aan evenementenoverzicht"
          ]
        },
        {
          date: 'Do 15 oktober 2020',
          version: 1000113,
          description: 'Algemene verbeteringen',
          items: [
            "Changelog toegevoegd",
            "Laden van event verbeterd"
          ]
        }
      ]
    }
  },
  computed: {
    currentVersion() {
      return this.displayVersion(this.changelog[0].version)
    },
    limitedChangelog() {
      return this.changelog.slice(0, 2)
    }
  },
  methods: {
    displayVersion(ver) {
      // abbccde = a.b.c d (alpha: 1, beta: 3, RC: 5, stable: 7, PL: 9) e
      const spl = "" + ver
      let a = spl.substr(0, 1)
      let b = spl.substr(1, 2)
      let c = spl.substr(3, 2)
      let d = spl.substr(5, 1)
      let e = spl.substr(6, 1)

      let l = ""
      switch (d) {
        case "1":
          l = " Alpha";
          break;
        case "3":
          l = " Beta";
          break;
        case "5":
          l = " Release Candidate";
          break;
        case "9":
          l = " Patch";
          break;
      }

      if (b.startsWith("0"))
        b = b.substr(1)

      if (c.startsWith("0"))
        c = c.substr(1)

      if (e === "0")
        e = ""

      return a + "." + b + "." + c + l + " " + e
    }
  }
}
</script>